import { FaEnvelope, FaMapMarkedAlt, FaClock, FaPhoneAlt } from 'react-icons/fa';

const contacts = [  
    { 
        id: 1, 
        icon: <FaPhoneAlt />,
        title: 'llamanos',
        content: [ '55-58-113-963   '],
    },
    {
        id: 2,
        icon: <FaEnvelope />,
        title: 'Email',
        content: [ 'vivocabletv@gmail.com'],
    },
    {
        id: 3,
        icon: <FaMapMarkedAlt />,
        title: 'Dirección',
        content: ['Leandro Valle No. 80 San Lorenzo Acopilco, 05410 Cuajimalpa '],
    },
    {
        id: 4,
        icon: <FaClock />,
        title: 'Horario',
        content: ['LUNES - VIERNES 09:00AM - 06:00PM '],
    },
 
];

export default contacts;