import { images } from '../constants';

const home = [
    {    
        id: 1,
        image: images.home1,
        title: 'Aprovecha los Paquetes',
        content: 'Internet desde $430 al mes.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'Aprovecha los Paquetes',
        content: 'Television desde $300 al mes.', 
    },
    { 
        id: 3,
        image: images.home3,
        title: 'Aprovecha los Paquetes',
        content: 'Televisión + Internet desde $530 al mes.',     
    },

];

export default home;