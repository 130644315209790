import React, { useState } from 'react';
import './ServiceSingle.css';

import { FaqItem, Lightbox, PageTitle, SidebarHeading } from '../../../components';
import { services } from '../../../constants';
import { renderIcon } from '../../../utils';

import { BsListCheck } from 'react-icons/bs';
import { FaCheck, FaPencilRuler } from 'react-icons/fa';
import { IoConstruct } from 'react-icons/io5';

import { Link, useLocation } from 'react-router-dom';

 
  
const ServiceSingle = () => { 
 
  const location = useLocation();  
  const serviceSingle = location.state; 

  // State to manage the active accordion for container 1
  const [activeAccordionId, setActiveAccordionId] = useState(null);

  // Function to handle accordion click event for container 1
  const handleAccordionClick = (id) => {
    setActiveAccordionId((prevId) => (prevId === id ? null : id));
  };

  return (
    <>
      {/* Page Title */}
      <PageTitle title={'Tramites'} page={'Tramites'} />

      <section className='service-single'>

        <div className='sidebar service-sidebar'>

          <div className='category sidebar-item'>

            <SidebarHeading title={'Todos los Tramites'} />

            <div className='box-container'>
              {services.map(service => {
                return  (                
                /* Link to the individual service page */
                <Link
                  to={{
                    pathname: '/Services/' + service.title,
                  }}
                  location = {{service}}
                  state= {service}
                  key={service.id}
                  className='item'><span><div className='icon'>{renderIcon(service.title)}</div>{service.title}</span> {/* Service Title */}
                </Link> )
              })}
            </div>

            </div>

        </div>

        <div className='service-info'>

          {/* Service Image */} 
          <div className='image'>
            <img src={serviceSingle.image} alt='Blog' />
          </div>

          <div className='content'>

            {/* Service heading */}
            <h3 className='main-heading'>{serviceSingle.title}</h3>

            {/* Service Content */}
            {serviceSingle.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}

          </div>

          {/* Service Image gallery */}
          {/* <Lightbox images={serviceSingle.gallery} className={'service'} /> */}

          {/* Section for service benefits */}
          <div className='service-benefits'>

            <h3 className='main-heading'>{serviceSingle.title2}</h3>

            {/* Service benefits paragraphs */}
            {/* 
            {serviceSingle.benefits.paragraphs.map((paragraph, index)=> {
              return <p key={index}>{paragraph}</p>
            })}
          */}
            
            {/* List of service benefits */}
            <ul>
              {serviceSingle.benefits.list.map((benefit, index) => {
                return <li key={index}><FaCheck className='icon' />{benefit}</li>
              })}
            </ul>

          </div>

          <div className='service-benefits'>
  <h3 className='main-heading'>{serviceSingle.title3}</h3>
  {/* List of service benefits */}
  <ul>
    {serviceSingle && serviceSingle.benefits && serviceSingle.benefits.list2 ? (
      serviceSingle.benefits.list2.map((benefit, index) => (
        <li key={index}><FaCheck className='icon' />{benefit}</li>
      ))
    ) : (
      <p></p> // o cualquier otro manejo de caso de "no encontrado" o "cargando"
    )}
  </ul>
</div>


          {/* Section for service workflow */}
          {/* <div className='service-workflow'>

            <h3 className='sub-heading'>Work flow</h3>
            {serviceSingle.workFlow.paragraphs.map((paragraph, index)=> {
              return <p key={index}>{paragraph}</p>
            })}

            <div className='box-container'>

              <div className='work-item'>
                <BsListCheck className='icon' /> 
                <h4>planning</h4>
                <p>{serviceSingle.workFlow.workItems.planning}</p>
              </div>

              <div className='work-item'>
                <FaPencilRuler className='icon' /> 
                <h4>Design</h4> 
                <p>{serviceSingle.workFlow.workItems.design}</p> 
              </div>

              <div className='work-item'>
                <IoConstruct className='icon' />
                <h4>Construct</h4> 
                <p>{serviceSingle.workFlow.workItems.construct}</p> 
              </div>

            </div>
          </div> */}

          {/* Section for frequently asked questions */}
          {/* <div className='service-faqs faq'>

            <h3 className='sub-heading'>frequently asked questions</h3>

            <div className='accordion-container'>
              {serviceSingle.faqs.map((faq) => {
                return (            
                  <FaqItem
                    key={faq.id}
                    id={faq.id}
                    title={faq.heading}
                    content={faq.content}
                    isActive={faq.id === activeAccordionId}
                    onAccordionClick={handleAccordionClick}
                  /> )
              })} 
            </div>
          </div> */}
        
        </div> 

      </section> 
    </> 
  );
}

export default ServiceSingle;
