import React from 'react';
import './TeamItem.css';
import { Link } from 'react-router-dom';

const TeamItem = (props) => {
  const { id, name, team } = props;

  return (

    <div className='team-item' key={id}>
      {/* Team member content */}
      <div className='content'>
        <Link
          to={{
            pathname: '/Team/' + name,
          }}
          state={team}
          className='main-heading'>
          <h2>{name}</h2> {/* Team member name */}
        </Link>
      </div>
    </div>
  );
}

export default TeamItem;
