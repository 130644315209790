import React from 'react';
import './Logo.css';

import { images } from '../../constants';

import { Link } from "react-router-dom";

 
  
const Logo = () => {   

  return ( 
    <Link to='/' className='logo'>

      {/* Logo Image */} 
      <img src={images.logo} alt='logo' /> 

      {/* Logo Text */} 
      <div className='logo-name'>
        <h3>VivoCable</h3> {/* Logo Heading */} 
      </div>
      
    </Link>
  );

}

export default Logo;