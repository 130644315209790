/*Global*/
import logo from '../assets/Logo/Logo.png';
import pageTitle from '../assets/PageTitle/PageTitle.jpg';

 
/*Home*/ 
import home1 from '../assets/Home/Home-1.jpg'; 
import home2 from '../assets/Home/Home-2.jpg';
import home3 from '../assets/Home/Home-3.jpg'; 


/*Heading*/ 
import heading from '../assets/Heading/Heading.png'; 


/*Sponsors*/
import sponsor1 from '../assets/Sponsors/Sponsor-1.png';
import sponsor2 from '../assets/Sponsors/Sponsor-2.png';
import sponsor3 from '../assets/Sponsors/Sponsor-3.png';
import sponsor4 from '../assets/Sponsors/Sponsor-4.png';
import sponsor5 from '../assets/Sponsors/Sponsor-5.png';
import sponsor6 from '../assets/Sponsors/Sponsor-6.png';
import sponsor7 from '../assets/Sponsors/Sponsor-7.png';
import sponsor8 from '../assets/Sponsors/Sponsor-8.png';
 

/*About*/ 
import about1 from '../assets/About/About-1.jpg';
import about2 from '../assets/About/About-2.jpg';

/*About*/ 
import defensa1 from '../assets/About/About-1.jpg';
import defensa2 from '../assets/About/About-2.jpg';


/*Why-Us*/ 
import whyUs from '../assets/Why-Us/Why-Us.jpg';


/*Team*/
import team1 from '../assets/Team/Team-1.jpg';
import team2 from '../assets/Team/Team-2.jpg';
import team3 from '../assets/Team/Team-3.jpg';
import team4 from '../assets/Team/Team-4.jpg';
import team5 from '../assets/Team/Team-5.jpg';
import team6 from '../assets/Team/Team-6.jpg';


/*Testimonials*/
import testimonial1 from '../assets/Testimonials/Pic-1.jpg';
import testimonial2 from '../assets/Testimonials/Pic-2.jpg';
import testimonial3 from '../assets/Testimonials/Pic-3.jpg';
import testimonial4 from '../assets/Testimonials/Pic-4.jpg';
 

/*FAQs*/ 
import FAQs from '../assets/FAQs/FAQs.jpg';


/*service*/
import service1 from '../assets/Service/Services/Service-1.jpg';
import service1Gallery1 from '../assets/Service/Service-Gallery/1.jpg';
import service1Gallery2 from '../assets/Service/Service-Gallery/2.jpg';

import service2 from '../assets/Service/Services/Service-2.jpg';
import service2Gallery1 from '../assets/Service/Service-Gallery/1.jpg';
import service2Gallery2 from '../assets/Service/Service-Gallery/2.jpg';

import service3 from '../assets/Service/Services/Service-3.jpg';
import service3Gallery1 from '../assets/Service/Service-Gallery/1.jpg';
import service3Gallery2 from '../assets/Service/Service-Gallery/2.jpg';

import service4 from '../assets/Service/Services/Service-4.jpg';
import service4Gallery1 from '../assets/Service/Service-Gallery/1.jpg';
import service4Gallery2 from '../assets/Service/Service-Gallery/2.jpg';

import service5 from '../assets/Service/Services/Service-5.jpg';
import service5Gallery1 from '../assets/Service/Service-Gallery/1.jpg';
import service5Gallery2 from '../assets/Service/Service-Gallery/2.jpg';

import service6 from '../assets/Service/Services/Service-6.jpg';
import service6Gallery1 from '../assets/Service/Service-Gallery/1.jpg';
import service6Gallery2 from '../assets/Service/Service-Gallery/2.jpg';

import service7 from '../assets/Service/Services/Service-7.jpg';


import service8 from '../assets/Service/Services/Service-8.jpg';


import service9 from '../assets/Service/Services/Service-9.jpg';


/*project*/
import project1 from '../assets/Project/Projects/Project-1.jpg';
import project1Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project1Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project2 from '../assets/Project/Projects/Project-2.jpg';
import project2Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project2Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project3 from '../assets/Project/Projects/Project-3.jpg';
import project3Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project3Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project4 from '../assets/Project/Projects/Project-4.jpg';
import project4Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project4Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project5 from '../assets/Project/Projects/Project-5.jpg';
import project5Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project5Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project6 from '../assets/Project/Projects/Project-6.jpg';
import project6Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project6Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project7 from '../assets/Project/Projects/Project-7.jpg';
import project7Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project7Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project8 from '../assets/Project/Projects/Project-8.jpg';
import project8Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project8Gallery2 from '../assets/Project/Project-Gallery/2.jpg';

import project9 from '../assets/Project/Projects/Project-9.jpg';
import project9Gallery1 from '../assets/Project/Project-Gallery/1.jpg';
import project9Gallery2 from '../assets/Project/Project-Gallery/2.jpg';


/*Blogs*/
import blog1 from '../assets/Blog/Blogs/Blog-1.jpg';
import blog1Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog1Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog1Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog1Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog1Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog2 from '../assets/Blog/Blogs/Blog-2.jpg';
import blog2Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog2Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog2Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog2Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog2Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog3 from '../assets/Blog/Blogs/Blog-3.jpg';
import blog3Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog3Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog3Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog3Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog3Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog4 from '../assets/Blog/Blogs/Blog-4.jpg';
import blog4Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog4Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog4Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog4Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog4Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog5 from '../assets/Blog/Blogs/Blog-5.jpg';
import blog5Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog5Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog5Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog5Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog5Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog6 from '../assets/Blog/Blogs/Blog-6.jpg';
import blog6Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog6Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog6Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog6Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog6Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog7 from '../assets/Blog/Blogs/Blog-7.jpg';
import blog7Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog7Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog7Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog7Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog7Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog8 from '../assets/Blog/Blogs/Blog-8.jpg';
import blog8Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog8Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog8Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog8Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog8Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog9 from '../assets/Blog/Blogs/Blog-9.jpg';
import blog9Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog9Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog9Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog9Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog9Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog10 from '../assets/Blog/Blogs/Blog-10.jpg';
import blog10Gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog10Gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog10Comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog10Comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog10Comment3 from '../assets/Blog/Comments/Pic-3.jpg';

/*Shop*/
import product1Gallery1 from '../assets/Shop/Products/1.jpg';
import product1Gallery2 from '../assets/Shop/Products/2.jpg';
import product1Gallery3 from '../assets/Shop/Products/3.jpg';
import product1Gallery4 from '../assets/Shop/Products/4.jpg';
import product1Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product1Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product2Gallery1 from '../assets/Shop/Products/2.jpg';
import product2Gallery2 from '../assets/Shop/Products/3.jpg';
import product2Gallery3 from '../assets/Shop/Products/4.jpg';
import product2Gallery4 from '../assets/Shop/Products/5.jpg';
import product2Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product2Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product3Gallery1 from '../assets/Shop/Products/3.jpg';
import product3Gallery2 from '../assets/Shop/Products/4.jpg';
import product3Gallery3 from '../assets/Shop/Products/5.jpg';
import product3Gallery4 from '../assets/Shop/Products/6.jpg';
import product3Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product3Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product4Gallery1 from '../assets/Shop/Products/4.jpg';
import product4Gallery2 from '../assets/Shop/Products/5.jpg';
import product4Gallery3 from '../assets/Shop/Products/6.jpg';
import product4Gallery4 from '../assets/Shop/Products/7.jpg';
import product4Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product4Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product5Gallery1 from '../assets/Shop/Products/5.jpg';
import product5Gallery2 from '../assets/Shop/Products/6.jpg';
import product5Gallery3 from '../assets/Shop/Products/7.jpg';
import product5Gallery4 from '../assets/Shop/Products/8.jpg';
import product5Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product5Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product6Gallery1 from '../assets/Shop/Products/6.jpg';
import product6Gallery2 from '../assets/Shop/Products/7.jpg';
import product6Gallery3 from '../assets/Shop/Products/8.jpg';
import product6Gallery4 from '../assets/Shop/Products/9.jpg';
import product6Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product6Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product7Gallery1 from '../assets/Shop/Products/7.jpg';
import product7Gallery2 from '../assets/Shop/Products/8.jpg';
import product7Gallery3 from '../assets/Shop/Products/9.jpg';
import product7Gallery4 from '../assets/Shop/Products/1.jpg';
import product7Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product7Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product8Gallery1 from '../assets/Shop/Products/8.jpg';
import product8Gallery2 from '../assets/Shop/Products/9.jpg';
import product8Gallery3 from '../assets/Shop/Products/1.jpg';
import product8Gallery4 from '../assets/Shop/Products/2.jpg';
import product8Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product8Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product9Gallery1 from '../assets/Shop/Products/9.jpg';
import product9Gallery2 from '../assets/Shop/Products/1.jpg';
import product9Gallery3 from '../assets/Shop/Products/2.jpg';
import product9Gallery4 from '../assets/Shop/Products/3.jpg';
import product9Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product9Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import legal1 from '../assets/Audio/Legal - Inicio.mp3';
import legal2 from '../assets/Audio/Legal - Aviso.mp3';
import legal3 from '../assets/Audio/Legal - Codigo.mp3';
import legal4 from '../assets/Audio/Legal - Herramientas.mp3';
import legal5 from '../assets/Audio/Legal - Version.mp3';
import legal6 from '../assets/Audio/Legal - Practicas.mp3';



const Images = {

    /* Audios */
    legal1,
    legal2,
    legal3,
    legal4,
    legal5,
    legal6,

    /*Global*/
    logo,
    pageTitle,

    /*Home*/
    home1,
    home2,
    home3,

    /*Heading*/
    heading,
    
    /*Sponsors*/
    sponsor1,
    sponsor2,
    sponsor3,
    sponsor4,
    sponsor5,
    sponsor6,
    sponsor7,
    sponsor8,

    /*About*/
    about1,
    about2,

        /*defensa*/
        defensa1,
        defensa2,

    /*Why-Us*/ 
    whyUs,

    /*Team*/
    team1,
    team2,
    team3, 
    team4,
    team5, 
    team6,

    /*Testimonials*/
    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,

    /*FAQs*/ 
    FAQs,

    /*service*/
    service1,
    service1Gallery1,
    service1Gallery2,

    service2,
    service2Gallery1,
    service2Gallery2,

    service3,
    service3Gallery1,
    service3Gallery2,

    service4,
    service4Gallery1,
    service4Gallery2,

    service5,
    service5Gallery1,
    service5Gallery2,

    service6,
    service6Gallery1,
    service6Gallery2,

    service7,

    service8,

    service9,

    /*project*/
    project1,
    project1Gallery1,
    project1Gallery2,

    project2,
    project2Gallery1,
    project2Gallery2,

    project3,
    project3Gallery1,
    project3Gallery2,

    project4,
    project4Gallery1,
    project4Gallery2,

    project5,
    project5Gallery1,
    project5Gallery2,

    project6,
    project6Gallery1,
    project6Gallery2,

    project7,
    project7Gallery1,
    project7Gallery2,

    project8,
    project8Gallery1,
    project8Gallery2,

    project9,
    project9Gallery1,
    project9Gallery2,

    /*Blogs*/
    blog1,
    blog1Gallery1,
    blog1Gallery2,
    blog1Comment1,
    blog1Comment2,
    blog1Comment3,

    blog2,
    blog2Gallery1,
    blog2Gallery2,
    blog2Comment1,
    blog2Comment2,
    blog2Comment3,

    blog3,
    blog3Gallery1,
    blog3Gallery2,
    blog3Comment1,
    blog3Comment2,
    blog3Comment3,

    blog4,
    blog4Gallery1,
    blog4Gallery2,
    blog4Comment1,
    blog4Comment2,
    blog4Comment3,

    blog5,
    blog5Gallery1,
    blog5Gallery2,
    blog5Comment1,
    blog5Comment2,
    blog5Comment3,

    blog6,
    blog6Gallery1,
    blog6Gallery2,
    blog6Comment1,
    blog6Comment2,
    blog6Comment3,

    blog7,
    blog7Gallery1,
    blog7Gallery2,
    blog7Comment1,
    blog7Comment2,
    blog7Comment3,

    blog8,
    blog8Gallery1,
    blog8Gallery2,
    blog8Comment1,
    blog8Comment2,
    blog8Comment3,

    blog9,
    blog9Gallery1,
    blog9Gallery2,
    blog9Comment1,
    blog9Comment2,
    blog9Comment3,

    blog10,
    blog10Gallery1,
    blog10Gallery2,
    blog10Comment1,
    blog10Comment2,
    blog10Comment3,

    /*Shop*/
    product1Gallery1,
    product1Gallery2,
    product1Gallery3, 
    product1Gallery4,
    product1Review1,
    product1Review2,

    product2Gallery1,
    product2Gallery2,
    product2Gallery3,
    product2Gallery4,
    product2Review1,
    product2Review2,

    product3Gallery1,
    product3Gallery2,
    product3Gallery3,
    product3Gallery4,
    product3Review1,
    product3Review2,

    product4Gallery1,
    product4Gallery2,
    product4Gallery3,
    product4Gallery4,
    product4Review1,
    product4Review2,

    product5Gallery1,
    product5Gallery2,
    product5Gallery3,
    product5Gallery4,
    product5Review1,
    product5Review2,

    product6Gallery1,
    product6Gallery2,
    product6Gallery3,
    product6Gallery4,
    product6Review1,
    product6Review2,

    product7Gallery1,
    product7Gallery2,
    product7Gallery3,
    product7Gallery4,
    product7Review1,
    product7Review2,

    product8Gallery1,
    product8Gallery2,
    product8Gallery3,
    product8Gallery4,
    product8Review1,
    product8Review2,

    product9Gallery1,
    product9Gallery2,
    product9Gallery3,
    product9Gallery4,
    product9Review1,
    product9Review2,

};

export default Images;