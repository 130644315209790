import React, { useEffect, useState } from 'react';
import './Project.css';
import axios from 'axios'; // Importa Axios para hacer la solicitud HTTP

import { ProjectItem, ProjectTabs } from '../../components';
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

const Project = () => {
  // State
  const [projects, setProjects] = useState([]);
  const [category, setCategory] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);
  const [index, setIndex] = useState(-1);

  // Handlers para la navegación del lightbox
  const currentImage = projects[index];
  const nextIndex = (index + 1) % projects.length;
  const nextImage = projects[nextIndex] || currentImage;
  const prevIndex = (index + projects.length - 1) % projects.length;
  const prevImage = projects[prevIndex] || currentImage;

  const openLightbox = (index) => setIndex(index);
  const closeLightbox = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  // Obtener datos del API al cargar el componente
  useEffect(() => {
    axios.get('https://back.vivocable.tv/api/servicios')
      .then(response => {
        setProjects(response.data.data); // Guarda los datos obtenidos del API en el estado
        setFilteredImages(response.data.data); // Inicialmente muestra todos los proyectos
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Filtrar imágenes basado en la categoría seleccionada
  useEffect(() => {
    if (category === 'all') {
      setFilteredImages(projects);
    } else {
      const filtered = projects.filter(item =>
        item.tipo_servicio.toLowerCase() === category.toLowerCase()
      );
      setFilteredImages(filtered);
    }
  }, [category, projects]);

  // Obtener categorías únicas de los proyectos
  const categories = ['all', ...new Set(projects.map(item => item.tipo_servicio))];

  return (
    <div className='projects'>
      {/* Renderizar los botones de tabs */}
      <ul className='controls'>
        {categories.map(item => (
          <ProjectTabs
            key={item}
            id={item}
            title={item}
            setSelected={setCategory}
            active={category === item}
          />
        ))}
      </ul>

      <div className='box-container'>
        {/* Renderizar los items del proyecto */}
        {filteredImages.map((item, index) => (
          <ProjectItem
            key={item.id}
            id={index}
            image={`https://back.vivocable.tv/${item.imagen_principal}`}
            title={item.nombre_servicio}
            category={item.tipo_servicio}
            project={item}
            popup={openLightbox}
          />
        ))}

        {/* Renderizar el lightbox cuando el índice no es -1 */}
        {index !== -1 && (
          <Lightbox
            mainSrc={currentImage.imagen_principal}
            imageTitle={currentImage.nombre_servicio}
            mainSrcThumbnail={currentImage.imagen_principal}
            nextSrc={nextImage.imagen_principal}
            nextSrcThumbnail={nextImage.imagen_principal}
            prevSrc={prevImage.imagen_principal}
            prevSrcThumbnail={prevImage.imagen_principal}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>
    </div>
  );
};

export default Project;
