import { FaBriefcase, FaUserCog, FaAward, FaUserFriends } from 'react-icons/fa';

  
const counter = [
    { 
        id: 1, 
        icon: <FaBriefcase />,
        title: 'Trabajadores',
        count: '50',
    },
    {
        id: 2,
        icon: <FaUserCog />,
        title: 'Trabajos',
        count: '100',
    },
    {
        id: 3,
        icon: <FaAward />,
        title: 'Años',
        count: '12',
    },
    {
        id: 4,
        icon: <FaUserFriends />,
        title: 'clientes',
        count: '200',
    },
 
];

export default counter;