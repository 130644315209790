import { FaEnvelope, FaMapMarkedAlt, FaClock, FaPhoneAlt } from 'react-icons/fa';

const cobertura = [  
    { 
        id: 1, 
        title: 'Colonias:',
    },
    {
        id: 2,
        content: [ 'San Lorenzo Acopilco'],
    },
    {
        id: 3,
        content: [ 'Tianguillo'],
    },
    {
        id: 4,
        content: ['La Pila'],
    },
    {
        id: 5,
        content: ['Maromas'],
    },
    {
        id: 6,
        content: ['Cruz Blanca'],
    },
    {
        id: 7,
        content: ['Teopazulco'],
    },
    {
        id: 8,
        content: ['Las Cruces'],
    },
    {
        id: 9,
        content: ['Xalpa'],
    },
 
];

export default cobertura;