import React from 'react';
import './AboutUs.css';

import { PageTitle } from '../../../components';
import { About, Testimonials, Counter, Sponsors, WhyUs, Team } from '../../../sections';



const AboutUs = () => { 
  return ( 
    <>  
      {/* Page title */}
      <PageTitle title={'Defensa de las Audiencias'} page={'Audiencias'} />

      <section className='about-us'>

        {/* Team section */}
        <Team />

      </section>
    </>
  );
}

export default AboutUs;