import { images } from "../constants";

const testimonials = [  
    {  
        id: 1,
        image: images.testimonial1, 
        name: 'Jesus H', 
        title: '',
        content: 'La verdad, estoy sorprendida con VivoCable. Antes tenía mil problemas de conexión y desde que cambié, todo va de maravilla. Lo mejor de todo es el servicio al cliente; una vez tuve un problemilla y enseguida me lo solucionaron, super amables.',
    }, 
    { 
        id: 2,
        image: images.testimonial2, 
        name: 'Ana Meza', 
        title: '',
        content: 'Con tanto proveedor de internet y tele, uno ya no sabe ni qué elegir, pero con VivoCable di en el clavo. La instalación fue en un abrir y cerrar de ojos y todo funciona a la perfección. ',
    },
    { 
        id: 3,
        image: images.testimonial3, 
        name: 'Andre R', 
        title: '',
        content: 'Soy de los que apenas sabe lo básico de tecnología, así que cuando me mudé solo, el tema de contratar internet y tele me daba vueltas. Un vecino me recomendó VivoCable y no podría estar más feliz. ',
    },    
    { 
        id: 4,
        image: images.testimonial4, 
        name: 'Nancy', 
        title: '',
        content: 'Trabajo en casa y, entre reuniones en línea y mi hobby de ver series, necesito que todo funcione a la perfección. Me pasé a VivoCable por un amigo que lo recomendó y, de verdad, ni un solo arrepentimiento. ',
    },
];

export default testimonials;