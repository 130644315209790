import React from 'react';
import './FaqItem.css';

import { FaPlus, FaMinus } from 'react-icons/fa';

const FaqItem = (props) => {
  
  // Destructuring props
  const { id, title, isActive, onAccordionClick, audio} = props;

  // Función para redirigir al usuario basado en el ID del FAQ
  const redirectToSection = (id) => {
    const urls = {
      1: 'https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FContrato%20Registrado%20en%20Profeco.pdf?alt=media&token=d2317dbb-77f9-4f61-85d5-e1aac0af244d',
      2: 'https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FAviso%20de%20Privacidad.pdf?alt=media&token=235823fc-2ec1-44c6-97f2-39cdf9d52670',
      3: 'https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FC%C3%B3digo%20de%20%C3%89tica.pdf?alt=media&token=aa0b1732-542b-4772-b0c5-df33876706c5',
      4: 'https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FHerramientas%20para%20empoderar%20a%20las%20personas%20usuarias%20de%20servicios%20de%20telecomunicaciones..pdf?alt=media&token=9defb38d-e594-4668-a2eb-bdf7c965e278',
      5: 'https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FVersi%C3%B3n%20ejecutiva%20de%20la%20Carta%20de%20Derechos%20m%C3%ADnimos..pdf?alt=media&token=8cec7331-7986-49e7-b74a-d8b39445e0fd',
      6: 'https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FC%C3%B3digo%20de%20Pr%C3%A1cticas%20Comerciales.pdf?alt=media&token=27c6745b-d969-4782-b675-948fbce2006f',
      7: 'https://firebasestorage.googleapis.com/v0/b/vivicable.appspot.com/o/pdf%2FC%C3%B3digo%20de%20Pol%C3%ADtica%20de%20Gesti%C3%B3n%20de%20Tr%C3%A1fico.pdf?alt=media&token=fcca1b9b-7bcd-4233-ae69-c77f4b55856f',
    };

    const url = urls[id];
    if (url) {
      // Abre la URL en una nueva pestaña
      window.open(url, '_blank');
    }
  };
 
  return (  
    <div className={`accordion ${isActive ? 'active' : ''}`}>
      <div className='accordion-heading' onClick={() => onAccordionClick(id)}>
        <h3>{title}</h3>
        <div className='icon'>{isActive ? <FaMinus /> : <FaPlus />}</div>
      </div>

      {isActive && (
        <div className='accordion-content'>
          <button className='buttonDownload' onClick={() => redirectToSection(id)}>Descargar</button>
          <audio controls src={audio} className='audioPlayer'>
            Tu navegador no soporta el elemento de audio.
          </audio>
        </div>
      )}
    </div>  
  );
};

export default FaqItem;
