import React from 'react';
import './TeamSingle.css';

import { PageTitle } from '../../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
 
  

const TeamSingle = () => {  
 
  const location = useLocation();  
  const team = location.state;

  const accounts = team.accounts;
  const skillsContent = team.skills;

  // Get the total count of Skills
  const skillsCount = skillsContent.length;

  // Calculate the halfway point of the Skills
  const halfCount = Math.ceil(skillsCount / 2);

  // Split the Skills into two arrays
  const skillsCount1 = skillsContent.slice(0, halfCount); // First half of Skills
  const skillsCount2 = skillsContent.slice(halfCount); // Second half of Skills


  return ( 
    <>

      {/* Page title */}
      <PageTitle title={'CALENDARIO DE REPORTES'} page={'Calendario De Reportes'} />

      <section className='team-single'>      

        <div className='box-container'>

          {/* Team Member Introduction */}
          <div className='intro'>

            {/* Team Member Image */}
            <div className='image'>
              <img src={team.image} alt='Blog' />
            </div>

        
          </div>

          {/* Team Member Details */}
          <div className='information'>

            <h3>{team.name}</h3> {/* Team Member Name */}
            <p>{team.title}</p> {/* Team Member Title */}

            {/* Team Member About */}
            <div className='team-about'>
              <h4>CALENDARIO DE REPORTES</h4> {/* Biography Title */}
              <div className='text'>{team.biography}</div> {/* Team Member Biography */}
            </div>

          </div> 

        </div>


      </section>
    </>
  )
  
}

export default TeamSingle;