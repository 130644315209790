import React, { Component } from 'react';
import './Cobertura.css';

import { Heading, PageTitle } from '../../components';
import { coberturaContent } from '../../constants';

class Cobertura extends Component {
  state = {
    codigoPostal: '',
    coberturaDisponible: null,
  };

  verificarCobertura = () => {
    const codigosPostalesValidos = [5410, 5700, 5050, 5790, 5730, 5400, 5750, 5710];
    const esValido = codigosPostalesValidos.includes(parseInt(this.state.codigoPostal));
    this.setState({ coberturaDisponible: esValido });
  };
  

  render() {
    return (
      <>
        <PageTitle title={'Cobertura'} page={'cobertura'} />

        <section className='cobertura' id='cobertura'>
          <div className='box-container'>

            <div className='cobertura-container'>
              <Heading title={'Cobertura'} />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.9860117539633!2d-99.33118762515961!3d19.326413281930343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d207809adb939b%3A0x6c1fd755a281ad2f!2sVivo%20Cable!5e0!3m2!1ses-419!2smx!4v1710789766531!5m2!1ses-419!2smx"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </div>

            <div className='cobertura-info'>
              <Heading title={'NUESTRA COBERTURA'} />
              {coberturaContent.map((cobertura, index) => {
                if (index === 0) { // Asumiendo que "Colonias:" es el primer elemento
                  return (
                    <div className='info-item' key={cobertura.id}>
                      <div className="content" style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ marginRight: '10px' }}>{cobertura.title}</h3>
                        <input
                          type="number"
                          placeholder="Código Postal"
                          value={this.state.codigoPostal}
                          onChange={(e) => this.setState({ codigoPostal: e.target.value })}
                          style={{ marginRight: '10px' }}
                        />
                        <button onClick={this.verificarCobertura}>Verificar</button>
                        {this.state.coberturaDisponible !== null && (
                          <p style={{ marginLeft: '10px' }}>
                            {this.state.coberturaDisponible
                              ? 'Cobertura disponible en tu zona 🎉'
                              : 'Lo sentimos, no tenemos cobertura en tu zona 😢'}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className='info-item' key={cobertura.id}>
                      <div className="content">
                        <h3>{cobertura.title}</h3>
                        {cobertura.content.map((value, index) => (
                          <p key={index}>{value}</p>
                        ))}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Cobertura;
