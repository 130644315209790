import React from 'react';
import './Services.css';

import { Heading } from '../../../components';
import { services } from '../../../constants';
import { renderIcon } from '../../../utils';

import { Link } from 'react-router-dom';  


    
const Services = () => { 

  return (
    <section className='services'>
 
      {/* Section heading */}
      <Heading title={'Tramites'} subTitle={'VivoCable'} />

      <div className='box-container'>    
        {// Map through the serviceContent array and render service items
          services
          .map((service) => { 

            return (
              <div className='service-item' key={service.id}>

                {/* Service Image Container */}
                <div className='image'>
                  <img src={service.image} alt='' /> {/* Service Image */}
                  <div className='icon'>{renderIcon(service.title)}</div> {/* Service Icon */}
                </div>
 
                {/* Service Content Container */}
                <div className='content'>
                  {/* Link to the individual service page */}
                  <Link
                    to={{ 
                      pathname: '/Services/' + service.title,
                    }}
                    location = {{service}}
                    state= {service}><h3>{service.title}</h3> {/* Service Title */}
                  </Link>
                  <p>{service.content}</p> {/* Service Content */}
                </div>


                
              </div>
            )
          })
        }
      </div>
 
    </section>
  )
}

export default Services