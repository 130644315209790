import React from 'react';
import './WhyUs.css';

import { images } from '../../../constants';

import { FaCheck } from 'react-icons/fa';

   
 
const WhyUs = () => {
  
  return (  
    <section className='why-us'>

    <div className='box-container'>

      <div className='content'>
        {/* Heading */}
        <div className='heading'>  
          <span>VivoCable</span> 
          <h3>¿Por Qué Elegirnos?</h3>
        </div>
        {/* Description */}
        <p>En VivoCable, entendemos que la conectividad y el entretenimiento son esenciales en el día a día de nuestros clientes. Por eso, ofrecemos soluciones integrales de internet y televisión que se adaptan a una amplia gama de necesidades, desde hogares hasta grandes empresas. Nuestra experiencia y dedicación nos permiten ofrecer servicios de alta calidad, asegurando que cada cliente encuentre la solución perfecta para sus requerimientos.</p>
        {/* List of reasons */}
        <ul> 
          <li><FaCheck className='icon' />Trabajadores Calificados</li>
          <li><FaCheck className='icon' />Mejores Materiales</li>
          <li><FaCheck className='icon' />Precios Razonables</li>
          <li><FaCheck className='icon' />Garantía de Satisfacción</li>
        </ul>
      </div>

      {/* Image */}
      <div className='image'>
        <img src={images.whyUs} alt="WhyUs-Pic" />
      </div> 

    </div>
  
  </section>
  )
  
}

export default WhyUs;