import { images } from '.';
 
const services = [  
    {   
        id: 1, 
        image: images.service1, 
        title: 'Contratación',
        title2: 'Requisitos',
        titlePage: 'interiorDesign',
        paragraphs: [
            'La contratación de nuestros servicios de televisión, internet o doble play, lo podrás realizar a través de nuestras siguientes opciones:',
            '1. Directamente en nuestras oficinas, consulta dirección, horarios y teléfono.',
            '2. A través de nuestro número de Atención a Clientes 55-58-113-963.',
            '3. Por medio de la página web, a través del "formulario de trámites" llenando los datos correspondientes, nuestro personal se pondrá en contacto contigo para continuar con el trámite.',
        ],
        gallery: [ 
            images.service1Gallery1,
            images.service1Gallery2,
        ],
        benefits: {
            list: [
                'Identificación vigente. (Credencial IFE/INE, Cédula Profesional, Cartilla Militar, Pasaporte)',
                'Comprobante de domicilio.',
                'RFC en caso de solicitar factura.',
                'Pago por instalación y mensualidad de acuerdo al paquete contratado.',
            ]
        },
    },

    {
        id: 2,
        image: images.service2,
        title: 'Instalación Y Activación del Servicio',
        icon: 'star',
        paragraphs: [
            'Una vez contrato nuestros servicios de televisión, internet o doble play, la entrega e instalación de equipo terminal no podrá ser mayor a 10 días hábiles a partir de la firma del contrato.',
            'Uno de nuestros técnicos se presentará debidamente identificado en el domicilio del Suscriptor para realizar la instalación de nuestros servicios.'
        ],
        gallery: [ 
            images.service2Gallery1,
            images.service2Gallery2,
        ],
        benefits: {
            title1: [
                'Instalación Y Activación del Servicio'
            ],
            paragraphs: [
                'Una vez contrato nuestros servicios de televisión, internet o doble play, la entrega e instalación de equipo terminal no podrá ser mayor a 10 días hábiles a partir de la firma del contrato.',
                'Uno de nuestros técnicos se presentará debidamente identificado en el domicilio del Suscriptor para realizar la instalación de nuestros servicios.'
            ],
            list: [

            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

    {
        id: 3,
        image: images.service3,
        title: 'Cancelación del Servicio',
        title2: 'Requisitos',
        icon: 'headset',
        paragraphs: [
            'Para la cancelación del servicio de televisión, internet o doble play, se puede realizar a través de las siguientes opciones:',
            '1. Directamente en nuestras oficinas, consulta dirección, horarios y teléfono.',
            '2. A través de nuestro número de Atención a Clientes 55-58-113-963.',
            '3. Por medio de la página web, a través del "formulario de trámites" llenando los datos correspondientes, nuestro personal se pondrá en contacto contigo para continuar con el trámite.',
        ],
        gallery: [ 
            images.service3Gallery1,
            images.service3Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'El trámite debe de ser realizado por el titular del servicio.',
                'En caso de que se solicite la cancelación de los servicios a través de un tercer, deberá de realizado con carta poder.',
                'Encontrarse al corriente en sus pagos.',
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

    {
        id: 4,
        image: images.service4,
        title: 'Cambio de Domicilio',
        title2: 'Requisitos',
        icon: 'phone',
        paragraphs: [
            'Para el cambio de domicilio, lo podrás realizar a través de las siguientes opciones:',
            '1. Directamente en nuestras oficinas, consulta dirección, horarios y teléfono.',
            '2. A través de nuestro número de Atención a Clientes 55-58-113-963.',
            '3. Por medio de la página web, a través del "formulario de trámites" llenando los datos correspondientes, nuestro personal se pondrá en contacto contigo para continuar con el trámite.',
        ],
        gallery: [ 
            images.service4Gallery1,
            images.service4Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'El trámite debe de ser realizado por el titular del servicio.',
                'En caso de que se solicite la cancelación de los servicios a través de un tercero, deberá de realizado con carta poder.',
                'Encontrarse al corriente en sus pagos.',
                'El nuevo domicilio debe de encontrarse dentro del área de servicio autorizado Consultar Cobertura.',
                'Realizar el pago por concepto de cambio de domicilio Consultar Tarifas.'
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

    {
        id: 5,
        image: images.service5,
        title: 'Solicitud Estado de Cuenta',
        title2: 'Requisitos',
        icon: 'calendar',
        paragraphs: [
            'Para solicitar su Estado de Cuenta, lo podrá realizar a través de:',
            '1. Por medio de correo electrónico a vivocabletv@gmail.com, con asunto "Solicitud Estado de Cuenta", en mensaje nombre del titular y número de contrato.',
            '2. Por medio de la página web, a través del "formulario de trámites" llenando los datos correspondientes, nuestro personal se pondrá en contacto contigo para continuar con el trámite.',
        ],
        gallery: [ 
            images.service5Gallery1,
            images.service5Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Número de contrato.',
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

    {
        id: 6,
        image: images.service6,
        title: 'Fallas en el Servicio',
        title2: 'Requisitos',
        title3: 'SISTEMA DE RECEPCIÓN DE FALLAS Y QUEJAS DEL SERVICIO DISPONIBLE 24HRS. AL DÍA, TODOS LOS DÍAS DEL AÑO',
        icon: 'thumbsUp',
        paragraphs: [
            'Si tienes alguna falla en el servicio de televisión, internet o doble play, podrás realizar el reporte a través de estas opciones:',
            '1. Directamente en nuestras oficinas, consulta dirección, horarios y teléfono.',
            '2. A través de nuestro número de Atención a Clientes 55-58-113-963.',
            '3. Por medio de la página web, a través del "formulario de trámites" llenando los datos correspondientes, nuestro personal se pondrá en contacto contigo para continuar con el trámite.',
        ],
        gallery: [ 
            images.service6Gallery1,
            images.service6Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Nombre del titular y número de contrato.',
                'Motivo de la falla.',
            ],
            list2: [
                '1. El usuario se comunica al centro de atención a clientes.',
                '2. Nuestro personal recibe el reporte, verifica el número de cliente, que se encuentre al corriente en pago, y lo turna al área técnica.',
                '3. El área técnica recibe el reporte de la falla y/o queja, se analiza el posible origen de la misma, ya sea error en el funcionamiento de algún equipo o daño físico en la red.',
                '4. Se identifica el tipo de falla y la ubicación en las líneas físicas externas en el domicilio reportado o en el CTC para trasladarse con el equipo y herramienta adecuada y el personal necesario.',
                '5. Si la falla se encuentra en las líneas físicas de la red, se traslada al lugar indicado con el equipo y herramienta necesaria para proceder a la pronta reparación, se realiza el informe detallado de la falla y acciones correctivas efectuadas.',
                '6. Si la falla se encuentra en el equipo con que cuenta el suscriptor en su domicilio, se procede al chequeo del mismo, para ajuste, reparación o cambio, se realiza un informe de las acciones realizadas en la corrección de la falla, para la sustitución de las piezas o equipos, recabando al final la firma de conformidad del suscriptor.',
                '7. Si la falla se detecta en el equipo mismo del CTC, se revisa el motivo para su pronta reparación y restablecimiento de la señal. de igual forma se realiza un reporte de la falla atendida y se realizan las observaciones necesarias para evitar la repetición de la misma.',
                '8. Se emite informe correspondiente de la falla que se atendió, área de origen para su control y archivo correspondiente.',
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

    {
        id: 7,
        image: images.service7,
        title: 'Quejas, Dudas o Aclaraciones',
        title2: 'Requisitos',
        title3: 'SISTEMA DE RECEPCIÓN DE FALLAS Y QUEJAS DEL SERVICIO DISPONIBLE 24HRS. AL DÍA, TODOS LOS DÍAS DEL AÑO.',
        icon: 'thumbsUp',
        paragraphs: [
            'El suscriptor podra reportar sus quejas por fallas y/o deficiencias en el servicio y/o equipo; así como consultas, contrataciones, sugerencias, y reclamaciones de manera gratuita a través de los siguiente medios:',
            '1. Directamente en nuestras oficinas, consulta dirección, horarios y teléfono.',
            '2. A través de nuestro número de Atención a Clientes 55-58-113-963.',
            '3. Por medio de la página web, a través del "formulario de trámites" llenando los datos correspondientes, nuestro personal se pondrá en contacto contigo para continuar con el trámite.',
        ],
        gallery: [ 
            images.service6Gallery1,
            images.service6Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Nombre del titular y número de contrato.',
                'Motivo de la queja, duda y/o reclamación.',
            ],
            list2: [
                '1. El usuario se comunica al centro de atención a clientes.',
                '2. Nuestro personal recibe el reporte, verifica el número de cliente, que se encuentre al corriente en pago, y lo turna al área técnica.',
                '3. El área técnica recibe el reporte de la falla y/o queja, se analiza el posible origen de la misma, ya sea error en el funcionamiento de algún equipo o daño físico en la red.',
                '4. Se identifica el tipo de falla y la ubicación en las líneas físicas externas en el domicilio reportado o en el CTC para trasladarse con el equipo y herramienta adecuada y el personal necesario.',
                '5. Si la falla se encuentra en las líneas físicas de la red, se traslada al lugar indicado con el equipo y herramienta necesaria para proceder a la pronta reparación, se realiza el informe detallado de la falla y acciones correctivas efectuadas.',
                '6. Si la falla se encuentra en el equipo con que cuenta el suscriptor en su domicilio, se procede al chequeo del mismo, para ajuste, reparación o cambio, se realiza un informe de las acciones realizadas en la corrección de la falla, para la sustitución de las piezas o equipos, recabando al final la firma de conformidad del suscriptor.',
                '7. Si la falla se detecta en el equipo mismo del CTC, se revisa el motivo para su pronta reparación y restablecimiento de la señal. de igual forma se realiza un reporte de la falla atendida y se realizan las observaciones necesarias para evitar la repetición de la misma.',
                '8. Se emite informe correspondiente de la falla que se atendió, área de origen para su control y archivo correspondiente.',
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

    {
        id: 8,
        image: images.service8,
        title: 'Bonificaciones o Compensaciones',
        title3: 'MECANISMOS DE BONIFICACIÓN Y COMPENSACIÓN.',
        icon: 'thumbsUp',
        paragraphs: [
            'En caso de solicitar una bonificación y/o compensación lo podrá solicitar a través de las siguientes opciones:',
            '1. Directamente en nuestras oficinas, consulta dirección, horarios y teléfono.',
            '2. A través de nuestro número de Atención a Clientes 55-58-113-963.',
            '3. Por medio de la página web, a través del "formulario de trámites" llenando los datos correspondientes, nuestro personal se pondrá en contacto contigo para continuar con el trámite.',
            'Vivo Cable deberá bonificar y compensar al suscriptor en los siguientes casos:',
            '1. Cuando por causas atribuibles al proveedor no se preste el servicio de telecomunicaciones en la forma y términos convenidos, contratados, ofrecidos o implícitos o información desplegada en la publicidad del proveedor, así como los índices y parámetros de calidad contratados o establecidos por el IFT, este debe de compensar al consumidor la parte proporcional del precio del servicio, plan o paquete que se dejó de prestar y como bonificación al menos el 20% del monto del periodo de afectación de la prestación del servicio.',
            '2. Cuando la interrupción del servicio sea por casos fortuitos o de fuerza mayor, si la misma dura más de 24 hrs. consecutivas siguientes al reporte que se realice el suscriptor, el proveedor hará la compensación por la parte proporcional del periodo en que se dejó de prestar el servicio contratado, la cual se verá reflejada en el siguiente recibo y/o factura. Además el proveedor deberá bonificar por lo menos el 20% del monto del periodo de afectación.',
            '3. Cuando se interrumpa el servicio por alguna causa previsible que repercuta de manera generalizada o significativa en la prestación del servicio, la misma no podrá afectar el servicio por mas de 24hrs. consecutivas; el proveedor dejara de cobrar al suscriptor la parte proporcional del precio del servicio que se dejó de prestar, y deberá bonificar por lo menos el 20% del monto del periodo que se efectuó.',
            '4. Cuando el proveedor realice cargos indebidos, deberá bonificar el 20% sobre el monto del cargo realizado indebidamente.',
            '5. A partir de de que el proveedor reciba la llamada por parte del suscriptor para reportar las fallas y/o interrupciones en el servicio, el proveedor procederá a verificar el tipo de falla y con base en ello, se determinará el tiempo necesario para la reparación, el cual no puede exceder las 24hrs, siguientes a la recepción del reporte.',
        
        ],
        gallery: [ 
            images.service6Gallery1,
            images.service6Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [

            ],
            list2: [
                'En caso de que proceda la bonificación y/o compensación, el proveedor se obliga a:',
                '1. Realizarlas a más tardar en la siguiente fecha de corte a partir de que se actualice alguno de los supuestos descritos anteriormente.',
                '2. Reflejarse en el siguiente estado de cuenta o factura, la bonificación y/o compensación realizada,Dicha bonificación y/o compensación se efectuará por los medios que pacten las partes.',]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },

    {
        id: 9,
        image: images.service9,
        title: 'Reconexión de Servicios',
        icon: 'thumbsUp',
        paragraphs: [
            'Después del día 16 de cada mes, se hará un corte en el servicio, para poder reactivar el servicio debe cubrir la tarifa de $100.00. Se puede realizar en la sucursal, haciendo click en formulario de trámites o al WhatsApp.',
        ],
        gallery: [ 
            images.service6Gallery1,
            images.service6Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
];

export default services;