import React from 'react';

import { Home, About, Counter, Testimonials, Sponsors, WhyUs } from '../../sections';

 

const HomePage = () => {

  return (
    <div className='homepage'>
      <Home /> {/* Home Section */}
      <About /> {/* About Section */} 
      <Counter /> {/* Counter Container */}
      <WhyUs /> {/* Why Us Section */} 
      <Testimonials /> {/* Testimonials Section */}   
      <Sponsors /> {/* Sponsors Section */} 
    </div>
  )
}

export default HomePage;