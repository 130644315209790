import React from 'react';
import './About.css';

import { Button, Heading } from '../../../components';
import { images } from '../../../constants'; 

import { FaBuilding, FaTools } from 'react-icons/fa';

  
 
const About = () => { 

  return ( 

    <section className='about'> 

      <div className='box-container'>

        {/* About images */}
        <div className='image'>
          <div className='sub-image1'><img src={images.about1} alt='About-Pic' /></div> {/* First image */}
          <div className='sub-image2'>
            <div className='experience-container'>
              <div className='experience'><span>12</span> años de experiencia</div> {/* Experience label */}
            </div>
            <img src={images.about2} alt='About-Pic' /> {/* Second image */}
          </div>
        </div>
 
        {/* About description */}
        <div className='content'> 
          <Heading title={'Sobre Nosotros'} subTitle={'VivoCable'} />
          <p>Vivo Cable es una empresa 100% mexicana, es una marca comercial que proporciona servicios de telecomunicaciones, a través de Título de Concesión otorgado por el Instituto Federal de Telecomunicaciones.</p> {/* Description */}
          <p>Vivo Cable ofrece el servicio de Single y Doble Play (internet y televisión).</p> {/* Description */}
          <Button link={'Projects'} title={'Planes'} /> {/* About-Us Button */}
          <Button link={'About-us'} title={'Cobertura'} /> {/* About-Us Button */}
        </div>

      </div>
      
    </section>
  );
}

export default About;