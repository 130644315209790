import React from 'react';
import './Register.css';

import { PageTitle } from '../../../components';

import { FaUserAlt, FaEnvelope, FaLock } from 'react-icons/fa'; 

import { Link } from 'react-router-dom';
 
   
 
const Register = () => {

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Reset the form fields
    event.target.reset();
  };

  return ( 
    <>
      {/* Page Title */}
      <PageTitle title={'Registro'} page={'Registro'} />

      <section className='register'>

        {/* Registration Form */}
        <form className='account-form' onSubmit={handleSubmit}>

          {/* User Icon */}
          <div className='icon'><FaUserAlt /></div>

          {/* Heading for the register form */}
          <h3>Registro</h3>

          {/* Name Input */}
          <div className="input-field">
            <label htmlFor="name"><FaUserAlt /></label> 
            <input type="text" name="name" placeholder="Nombre" id="name" className="box" />
          </div>

          {/* Email Input */}
          <div className="input-field">
            <label htmlFor="email"><FaEnvelope /></label>
            <input type="email" name="email" placeholder="Email" id="email" className="box" />
          </div>

          {/* Password Input */}
          <div className="input-field">
            <label htmlFor="password"><FaLock /></label>
            <input type="password" name="password" placeholder="Contraseña" id="password" className="box" />
          </div>

          {/* Repeat Password Input */}
          <div className="input-field">
            <label htmlFor="repeat-password"><FaLock /></label>
            <input type="password" name="repeat-password" placeholder="Contraseña" id="repeat-password" className="box" />
          </div>

          {/* Register Button */}
          <button type='submit' name='submit' id='submit' className='btn'>Registrar</button>

          {/* Login Link */}
          <p>¿Ya tienes una cuenta? <Link to='/login' className='link'>login</Link></p>

        </form>
        
      </section> 
    </>
  );
}

export default Register;